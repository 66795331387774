/** Image loading with blured smaller Image */
$(document).ready(function () {

    $(".boxwrapper [class^='col']").matchHeight();

    if (!window.isMobileDevice() && window.matchMedia("(min-width: 1024px)").matches) {
        $(".listitem").aganListitem();
    }

    var $mobileFilter = $("#agan-mobilefilter"),
        makairaForm = document.querySelector(".makaira-form ul"),
        slider = document.querySelector(".makaira-filter__range-slider");

    // click on mobile filter or sort button
    $mobileFilter.on("click tap", ".mobilefilter-button--filter", function () {
        $(this).parents("#agan-mobilefilter").toggleClass("agan-mobilefilter--open");
    });
    $mobileFilter.find(".mobilefilter-button--sort").on("change", function () {
        location.assign($(this).find("option:selected").data("href") || location.href);
    });

    //
    // when on small devices, catch makaira button events and disable them
    // this is an override of default makaira behaviour
    //
    if (makairaForm && window.matchMedia("(max-width: 767px)").matches) {
        makairaForm.addEventListener("click", function (event) {
            // exception for makaira buttons
            if (!event.target.classList.contains("makaira-filter__button")) {
                event.stopPropagation();
            }
        }, true);

        slider.noUiSlider.off("change");
    }

    // set Aria Label on range slider bullets
    if (slider) {
        let handles = slider.querySelectorAll('.noUi-handle');
        handles[0].setAttribute('aria-label', 'Lower price limit');
        handles[1].setAttribute('aria-label', 'Upper price limit');
    }
});

